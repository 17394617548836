import Logo from "./assets/images/123F-Logo-Stacked.svg";
import LogoAlt from "./assets/images/BigSmallFlowers.svg";

if (typeof window === "undefined") {
	self.window = self;
}

const url = {
	apiUrl: `https://${window.location.hostname !== "localhost" ? window.location.hostname : "www.123-flowers.local"}/api`,
	baseUrl: window.location.origin,
	currentUrl: window.location.href,
	liveUrl: "https://www.123-flowers.co.uk"
};

const config = {
	...url,
	cloudinaryUrl: url.liveUrl.replace("www.", "images.") + "/fetch/e_sharpen:80,q_auto,dpr_auto,f_auto",
	cloudinaryVideoUrl: url.liveUrl.replace("www.", "videos.") + "/fetch",
	apiUrl: url.apiUrl,
	// apiUrl: "https://localhost.123-flowers.co.uk/api",
	personalisedThumbUrl: "https://prestige-gifting-pw.s3-eu-west-1.amazonaws.com",
	reCaptchaKey: "6Ld03KkZAAAAAKX3wLeKju3xy912TjlLIgB0Dw0C",
	apiVersion: "v1",
	personalisedCardPrice: 3.99,
	personalisedCardCutoff: {
		0: 0,
		1: 18,
		2: 18,
		3: 18,
		4: 18,
		5: 18,
		6: 16
	},
	logo: true ? Logo : LogoAlt,
	siteName: "123 Flowers",
	orderPrefix: "123_",
	paymentMethodsEnabled: {
		paypalEnabled: true,
		applePayEnabled: true,
		googlePayEnabled: true,
		clearpayEnabled: true
	},
	currency: "£",
	referralVoucherAmount: 7.5,
	meta: {
		//these base ones need adding to the index.html as well
		title: "123 Flowers | Next Day Flowers",
		description: "123 Flowers offers beautiful flower arrangements for same day delivery by a local florist. Order online or call us today.",
		structuredData: []
	},
	cacheTimes: {
		homepage: 2,
		category: 2,
		product: 2
	},
	cacheVersion: "7cc3a505fe7186dc19e557ff753e2c8d248e26d3",
	socials: {
		facebook: "https://www.facebook.com/123flowersUK",
		instagram: "https://www.instagram.com/123flowersuk",
		twitter: "https://www.x.com/123Flowers_",
		tiktok: "https://www.tiktok.com/@123flowers_uk",
		pinterest: "https://www.pinterest.com/123FlowersUK"
	},
	recurringPaymentOptions: ["weekly", "fortnightly", "monthly"],
	GTM_ANALYTICS_ID: "UA-101122052-1",
	GTM_FB_PIXEL_ID: "1697484653615530",
	GTM_TIKTOK_PIXEL_ID: "CEVCOPBC77U5M6RIAJKG",
	GTM_BING_TAG_ID: "5708264",
	GTM_ADWORDS_CONVERSION_ID: "998763479",
	GTM_ADWORDS_CONVERSION_LABEL: "ceHcCOnYxQMQ19ef3AM",
	GTM_MOUSEFLOW_ID: "8a949406-1521-422b-9135-72df92e0bb22",
	GTM_AWIN_MERCHANT_ID: "16223",
	GTM_KELKOO_ID: "100499465",
	CLARITY_ID: "9mon4gj0yk",
	POSTHOG_KEY: "phc_SVrsdMdwSTC5ETuMDa3lkAIPNt9qT4NX7Ib269vgHJl",
	POSTHOG_API: "https://eu.posthog.com",
	PAYPAL_CLIENT_ID: import.meta.env.DEV ? "AZeIFv_YWEULoDoTVWbEB2N5JeWSPJwLplsHLTzMc9spcyxtqNpRhg9X7pi6e9UsuhgGyCgDpUGuFVEF" : "AQiMTdGItrXzDSeJlwKHXYFFKuEubHmQ7Sqc7ZQ2v-Ihx3cNY0fVPX9crjuY0ErPo1Do3OWGndQLODKm"
};

config.meta.structuredData = [
	{
		"@context": "http://schema.org",
		"@type": "Organization",
		name: config.siteName,
		url: config.liveUrl,
		logo: config.logo,
		sameAs: [config.socials.facebook, config.socials.instagram, config.socials.twitter, config.socials.tiktok, config.socials.pinterest]
	},
	{
		"@context": "http://schema.org",
		"@type": "WebSite",
		name: config.siteName,
		url: config.liveUrl
	}
];

export const dates = {
	todaysDate: new Date().toJSON().slice(0, 10),
	firstDate: new Date("2024-11-26").toJSON().slice(0, 10),
	earlyChristmasDate: new Date("2024-12-25").toJSON().slice(0, 10)
};

export default config;
